import React from 'react';
import image__about1 from "../img/image__about1.png";
import image__about2 from "../img/image__about2.png";
import service__logo from "../img/service__logo.svg";
import { Card } from "../components/cards/Card";
import { Link } from "react-router-dom";
import content from '../content';

export const HomePage = () => {

  return (
    <>
      <header className="header header__main-page" id="home">
        <div className="header__bottom">
          <div className="header__content">
            <div className="title title__space">
              <h1>
                <span className="title__normal">Welcome to</span>
                <br/>
                <span className="title__strong">ESH ALPHA LIMITED</span>
              </h1>
            </div>
            <span className="header__text">
            ESH ALPHA LIMITED is a team of specialists aimed at improving the performance of their clients.
          </span>
            <Link
              to="/contacts"
              className="btn btn--header"
              type="btn"
            >
              Contact us
            </Link>
          </div>

          <div className="header__content-image">
            <div className="header__image"></div>
          </div>
        </div>
      </header>

      <main>
        <section className="content about" id="about">
          <div className="about__images">
            <img className="img1" src={image__about1} alt="about 1"/>
            <img className="img2" src={image__about2} alt="about 1"/>
          </div>

          <div className="about__describe">
            <h2 className="service__card__title">
          <span className="service__card__title--purple">
            About
          </span>
              <br/>
              <span className="service__card__title--bold">
              ESH ALPHA LIMITED
            </span>
            </h2>
            <span className="about__text">
              We offer a wide range of consulting services in all areas of company and business activities. We develop and implement a system taking into account the individuality and specifics of the client. Our projects are targeted at enterprises of any form of ownership and business size.
            </span>
            <br/>
            <span className="about__text about__text--space">
              Our mission is to help our clients to improve their business as efficiently as possible with reliable, modern and easy-to-use software for automated business processes. We analyze the client's consistency and help him to find new opportunities to achieve the set goals and a high level of profit.
            </span>

            <button
              className="btn btn--color_about"
              type="btn"
            >
              Read more
            </button>
          </div>


        </section>

        <section className="service" id="service">
          <img
            className="service__logo"
            src={service__logo}
            alt="service logo"
          />
          <div className="cards">
            {content.services.filter(s => s.shortDescription !== null).map(card => (
              <Link  to={`/service/${card.id}`}>
                <Card
                  key={card.id}
                  title={card.titleFooter}
                  text={card.shortDescription}
                  image={card.icon}
                />
              </Link>
            ))}
          </div>
        </section>
      </main>
    </>
  );
};
