import itConsultingBig from './img/serviceImage/it-consulting-small.svg';
import itConsultingSmall from './img/serviceImage/it-consulting-big.svg';
import electricalEquipmentBig from './img/serviceImage/electrical-equipment-big.jpg';
import electricalEquipmentSmall from './img/serviceImage/electrical-equipment-small.jpg';
import consultingBig from './img/serviceImage/consulting-big.svg';
import consultingSmall from './img/serviceImage/consulting-small.svg';
import securityBig from './img/serviceImage/securityAndLogistics-big.jpg';
import securitySmall from './img/serviceImage/securityAndLogistics-small.jpg';
import wholesaleElectricalBig from './img/serviceImage/wholesaleElectrical-big.jpg';
import wholesaleElectricalSmall from './img/serviceImage/wholesaleElectrical-small.jpg';
import settingsIcon from './img/serviceImage/settings.svg';
import accountIcon from './img/serviceImage/ic_account_circle_48px.svg';
import assessment from './img/serviceImage/ic_assessment_48px.svg';
import custumerSmall from './img/serviceImage/CustomerService-small.jpg';
import custumerBig from './img/serviceImage/customerCare-big.jpg';
import itConsaltengIcon from './img/serviceImage/itConsaltingIcon.svg'
import itConsaltengIcon2 from './img/serviceImage/itConsalteng-Icon.svg'
import electricityIcon from './img/serviceImage/electricityIcon.svg'
import filmingBig from './img/serviceImage/service_filming_1.jpg'
import filmingSmall from './img/serviceImage/service_filming_2.jpg'

const content = {
  services: [
    {
      id: 6,
      mainTitle: 'Service',
      titleFooter: 'Filming and TV Production',
      subTitle: 'Filming and TV Production',
      shortDescription: null,
      description: '<p>ESH Alpha Limited provides video filming and TV Production services.</p><p>One of our new projects is “HEADS&TAILS. KAZAKHS”. This project is famous its exciting and informative content.</p><p>The hosts of the show travel to cities in different countries, introduce the viewer to the chosen city from a unique side, and talk about people with interesting lives and hobbies.</p><p>The project helps people know more about new places for travelling, provides services on research of travel services market including analysis of transportation service market, search locations, prices, supple and demand for the transport and travel services on the terrotiry of all over the world and the cities.</p><p>Each episode tells about one country or city from two points of view: the owner of an unlimited bank card and the traveler with a budget of 100 USD. Thus, this project is useful for tourists with any wallet.</p>',
      button: {
        label: 'start',
        url: '',
      },
      img1: filmingBig,
      img2: filmingSmall,
      icon: accountIcon,
    },
    {
      id: 1,
      mainTitle: 'Service',
      subTitle: 'It-consulting services, soft development, modeling',
      titleFooter: 'It-consulting services',
      shortDescription: 'We specialize in helping companies of all sizes to generate leads and sales through effective web design and online marketing.',
      description:
          '<p>We specialize in helping companies of all sizes to generate leads and sales through effective web design and online marketing. ESH ALPHA LIMITED specializes in Brand Development, Responsive Website Design, Search Engine Optimization, PPC Management, Social Media Marketing, Email Marketing, Video Marketing, and Software Development.</p><p> Our customer relationships are based on true collaboration, work side by side with their marketing teams to ensure that the most innovative campaigns are executed flawlessly, optimized daily for increased productivity and met and exceeded benchmarks. </p>'
      ,
    button: {
        label: 'start',
        url: '',
      },
      img1: itConsultingBig,
      img2: itConsultingSmall,
      icon: itConsaltengIcon,
    },
    {
      id: 2,
      mainTitle: 'Service',
      titleFooter: 'Support electronic equipment',
      subTitle: 'Support electronic & electrical equipment',
      shortDescription: 'One of our business is wholesale trade in household appliances and industrial appliances in a wide range. ',
      description: '<p>One of our business is wholesale trade in household appliances and industrial appliances in a wide range. Wholesale customers offer to purchase the equipment in full assortment of items: computer equipment, server hardware, storage systems, cluster systems, terminal solutions, backup systems, management tools, servers, and power supply. Switches, sockets, electric meters leading manufacturing brands, power tools, cables, circuit breakers, lamps, fixtures etc.</p> <p>All electronic devices that can be ordered are manufactured by reliable industrial brands. Therefore, their main advantage is the flawless assembly, reliability, operational safety, durability. All production has certificates of its origin and compliance with European and International standards.</p>',

      button: {
        label: 'start',
        url: '',
      },
      img1: electricalEquipmentBig,
      img2: electricalEquipmentSmall,
      icon: itConsaltengIcon2,
    },
    {
      id: 3,
      mainTitle: 'Service',
      titleFooter: 'Wholesale electrical equipment',
      subTitle: 'Wholesale electrical equipment',
      shortDescription: 'Our team offers you any electrical equipment the world\'s leading manufacturers at reasonable prices.',
      description: '<p>Our team offers you any electrical equipment the world\'s leading manufacturers at reasonable prices. Our large assortment also includes lamps without any problems. Lamps need to all and always. To illuminate the interior of the house, apartment, office, workroom or display of outdoor lighting: yard, parking lot, etc.</p><p>We offer a range of bulbs for all your needs, switches, sockets, electric meters leading manufacturing brands, power tools, cables, circuit breakers, lamps, fixtures etc.</p> ',
      button: {
        label: 'start',
        url: '',
      },
      img1: wholesaleElectricalSmall,
      img2: wholesaleElectricalBig,
      icon: electricityIcon,
    },
    {
      id: 4,
      mainTitle: 'Service',
      titleFooter: 'Consulting services',
      subTitle: 'Consulting services',
      shortDescription: 'We take care of our clients in various ways. We accompany their activities, advise on legal issues and accounting.',
      description: '<p>We take care of our clients in various ways. We accompany their activities, advise on legal issues and accounting.</p>  <p>We help to identify new resources and opportunities to increase customer profit, structure analytics and numbers that allow you to understand what the data shows. We offer the latest technology to simplify the data analysis process.</p>  <p><ul> <li>Consultіng  (Strаtegy & Operаtіons)</li><li>Rіsk Аdvіsory (Controls Trаnsformаtіon & Аssurаnce)</li> <li>Governаnce, Regulаtory & Rіsk)</li><li> Fіnаncіаl Аdvіsory (Mergers & Аcquіsіtіons, Fіnаncіаl Crіsіs)</li></ul> </p>  <p>Corporate compliance, integrated cross-border audit, tax dispute resolution strategies, tax audit strategy and consulting, tax dispute lifecycle management, dispute resolution, commercial law solutions, corporate law, mergers and acquisitions services, employment and pensions, tax disputes  - all options are according to your order.</p>',
      button: {
        label: 'start',
        url: '',
      },
      img1: consultingBig,
      img2: consultingSmall,
      icon: assessment,
    },
    {
      id: 5,
      mainTitle: 'Service',
      titleFooter: 'Security, logistics, and metal service',
      subTitle: 'Security, logistics, and metal service',
      shortDescription: 'For our clients, we have a special service - safe logistics of valuable goods, electronic, computers, servers  and also expensive materials.',
      description: '<p>For our clients, we have a special service - safe logistics of valuable goods, electronic, computers, servers and also expensive materials.</p><p>We made quality control organizing and delivery of lots of non-ferrous metals. Our specialists will select suppliers for you, organize product assessment, transportation and temporary storage. We have good partner in all important company’s.</p><p>By order of our partners, we can also serve groups of valuable metals with made-to-order deliveries</p><p>We offer the best and high-quality service, safe and timely delivery of your cargo. Your cargo will be in the reliable hands of our partners. It will be carefully received and stored in the terminals, loaded and transported in compliance with all confidentiality and legal norms.</p><ul><li>storage</li><li>terminal</li><li>loading</li><li>insurance</li><li>confidentiality</li><li>security</li></ul>',
      button: {
        label: 'start',
        url: '',
      },
      img1: securityBig,
      img2: securitySmall,
      icon: settingsIcon,
    },

    {
      id: 7,
      mainTitle: 'Service',
      titleFooter: 'Customer care',
      subTitle: 'Customer care',
      shortDescription: '<ul><li>24/7 availabiyty</li><li>Dedicated support consultant</li><li>Reliable service</li><li>Constant analysis of business’ performance</li></ul>',
      description: null,
      button: {
        label: 'start',
        url: '',
      },
      img1: custumerSmall,
      img2: custumerBig,
      icon: accountIcon,
    },
  ],
}

export default content;
