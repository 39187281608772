import React from 'react';
import './footer.scss'
import footer__logo from "../../img/footer__logo.svg";
import footer__title from "../../img/footer__title.svg";
import location from "../../img/location.svg";
import email from "../../img/email.svg";
import { Link } from "react-router-dom";

export const Footer = ({ content }) => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__top">
          <div className="footer__title">
            <Link to="/">
              <img src={footer__logo} alt="footer logo" />
            </Link>
            <img src={footer__title}  alt="title footer"/>
          </div>

          <div className="footer__contacts">
            <ul className="contacts">
              <li>
                <img src={location} alt="location"/>
                <a href="https://www.google.com/maps/place/71+Baggot+Street+Lower,+Dublin,+D02+P593,+%D0%98%D1%80%D0%BB%D0%B0%D0%BD%D0%B4%D0%B8%D1%8F/data=!4m2!3m1!1s0x48670ebdf00f94a7:0x6df1cea35ed2bfb0?sa=X&ved=2ahUKEwjUyJGI2v6BAxUXhv0HHSt2Dy8Q8gF6BAgQEAA&ved=2ahUKEwjUyJGI2v6BAxUXhv0HHSt2Dy8Q8gF6BAgTEAI"
                   target="_blank"
                   rel="noreferrer"
                   className="link"
                >
                  Ground Floor, 71 Lower Baggot Street, Dublin, D02 P593, Ireland
                </a>
              </li>
              <li>
                <img src={email} alt="email"/>
                <a href="mailto:info@esh-alpha.com" className="link">
                  info@esh-alpha.com
                </a></li>
            </ul>
          </div>

        </div>
        <div className="footer__bottom">

          <h3 className="list__title">Services</h3>

          <ul className="footer__service-list">
            {content.services.map(service => {
              if (!service.description) {
                return '';
              }
              return (
                <li key={service.id} className="list__item">
                  <Link
                    className="list__link"
                    to={`/service/${service.id}`}
                  >
                    {service.titleFooter}
                  </Link>
                </li>
              )
            })}
          </ul>

        </div>
      </div>
      <p className="footer__sign">© 2023 ESH Alpha all rights reserved</p>
    </footer>
  );
};
