import React, { useEffect } from 'react';
import L from 'leaflet';

export const MapLeaflet = () => {
  useEffect(() => {
    const coordinates = [53.3347864,-6.2457227];
    const map = L.map('map').setView(coordinates, 18);

    L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    L.marker(coordinates)
      .addTo(map)
      .bindPopup('Ground Floor, 71 Lower Baggot Street, Dublin, D02 P593, Ireland')
      .openPopup();

    return () => {
      map.remove();
    }
  }, []);

  return <>
    <div id="map"></div>
  </>
};
