import React, { useEffect } from 'react';
import './service.scss';
import { ServiceCard } from "../../components/ServiceCard";
import content from '../../content';
import { useParams } from "react-router-dom";

export const ServicePage = () => {
  const { id } = useParams();

  useEffect(() => {
    if (id && document.getElementById(`service-${id}`)) {
      const top = document.getElementById(`service-${id}`).offsetTop;

      window.scrollTo({
        top: top - 30,
        behavior: "smooth",
      });
    }
  }, [id])



  return <>
    <header className="header header__service header__service-page">
      <div className="header__service__content">
        <div className="title">
          <h1>
            <span className="title__normal">Our</span>
            &nbsp;
            <span className="title__strong">Services</span>
          </h1>
        </div>
        <span className="header__service__text">
          Our team is ready to accept any of your orders, develop specifications and execute them at the most convenient time for you.
        </span>
      </div>
    </header>
    <main className="main">

      {content.services.map((serv, index) => {
        if (!serv.description) {
          return '';
        }

        const {
          mainTitle,
          subTitle,
          description,
          id,
          img1,
          img2
        } = serv;

        return (
          <ServiceCard
            id={`service-${serv.id}`}
            key={id}
            styleCard={index % 2 !== 0}
            imgSmall={img1}
            imgBig={img2}
            title={mainTitle}
            subTitle={subTitle}
            description={description}
          />
        )
      })}
    </main>
  </>
};
